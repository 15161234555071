import { DirectionsBoat, ExpandLess, ExpandMore, LocationOn } from "@mui/icons-material"
import { Autocomplete, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from "@mui/material"
import React, { useEffect } from "react";
import { useState } from "react"
import { filterCruiseLines, filterDeparture, filterDestinations } from "../util/util";
const getCounts = (cruises, locations) => {
    return locations.map((location, index) => ({
        index,
        count: cruises.filter(cruise => cruise.itinerary.slice(1).reduce((prev, leg) => (prev || leg.title === location.title), false)).length,
        ...location
    }))
}
const Locations = ({ open, locations, destinations, setDestinations, cruises, departure, cruiseLines }) => {
    const [counts, setCounts] = useState([])
    useEffect(() => {
        setCounts(getCounts(filterCruiseLines(filterDeparture(cruises, departure), cruiseLines), locations))
    }, [cruises, destinations, cruiseLines, locations, departure])

    const [openLocations, setOpenLocations] = useState(true);

    const handleClick = () => {
        setOpenLocations(!openLocations);
    };

    const handleChange = (event, value) => {

        setDestinations(value.map(location => location.index));
    };

    return <>
        <ListItem disablePadding sx={{ display: 'block' }} onClick={handleClick}>
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                    }}
                >
                    <LocationOn />
                </ListItemIcon>
                <ListItemText primary='Destinations' sx={{ opacity: open ? 1 : 0 }} />
                {open ? (openLocations ? <ExpandLess /> : <ExpandMore />) : null}
            </ListItemButton>
        </ListItem>
        <Collapse in={openLocations && open} timeout="auto" unmountOnExit>
            <List component="div" >
                <Autocomplete
                    multiple
                    size="small"
                    id="cruiseLine-search"
                    options={counts}
                    getOptionLabel={(location) => `${location.title}` + (location.count !== undefined ? ` (${location.count})` : '')}
                    filterSelectedOptions
                    value={destinations.map(index => counts[index])}
                    onChange={handleChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            size="small"
                        />
                    )}
                    sx={{ mx: 1 }}
                />
            </List>
        </Collapse>
    </>
}

export default Locations