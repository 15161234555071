import React, { useEffect } from 'react'
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import Route from './Route'

const CruiseMap = ({ locations, destinations, cruises, departure }) => {

    return <>
        <MapContainer center={[-33.865143, 151.209900]} zoom={5} scrollWheelZoom={true} >
            <SetView departure={departure} locations={locations} />
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {destinations.map((location) => <Marker key={location} position={locations[location].position}>
                <Popup>
                    {locations[location].title}
                </Popup>
            </Marker>)}
            {departure !== null ? <Marker position={locations[departure].position}>
                <Popup>
                    {locations[departure].title}
                </Popup>
            </Marker> : null}
            {cruises.map((cruise, index) => <Route key={index} cruise={cruise} />)}
        </MapContainer>
    </>
}

const SetView = ({ departure, locations }) => {

    const map = useMap()
    useEffect(() => {
        if (departure !== null)
            map.setView(locations[departure].position, 5)
    }, [departure, locations, map])

}
export default CruiseMap