import logo from './logo.svg';
import './App.css';

import Main from './pages/Main';
import React from 'react';

function App() {
  return (
    <Main />
  );
}

export default App;
