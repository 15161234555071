import { Button } from "@mui/material"
import React from "react"
import { Circle, Polyline, Popup, Tooltip } from "react-leaflet"

const Route = ({ cruise }) => {
    return <>
        <Polyline
            pathOptions={{ color: 'rgb(24 118 210)', weight: 3 }}
            positions={cruise.itinerary.map(leg => leg.position)}>
            <Popup>
                <h4>
                    Cruise Line: {cruise.cruiseLine}
                </h4>
                <p>Itinerary:</p>
                {cruise.itinerary.map((leg, index) => <p key={index}>&bull; {leg.title}</p>)}
                {/* <Button>Book now</Button> */}

            </Popup>
            <Tooltip>
                <h4>
                    {cruise.cruiseLine}
                </h4>
                <p>
                    {cruise.itinerary.length} days

                </p>
            </Tooltip>
            {cruise.itinerary.map(leg => {
                return <Circle center={leg.position} pathOptions={{ fillColor: 'rgb(24 118 210)', color: 'rgb(24 118 210)', weight: 9 }}  >
                    <Tooltip>

                        <p>
                            {leg.title}

                        </p>
                    </Tooltip>
                </Circle>
            })}
        </Polyline>

    </>
}

export default Route