import { locations } from "../util/data/locations"

export const filterDeparture = (cruises, departure) => {
    if (departure !== null) {
        return cruises.filter((cruise) => cruise.itinerary[0].title === locations[departure].title)
    } else {
        return cruises
    }
}

export const filterDestinations = (cruises, destinations) => {
    if (destinations.length > 0) {
        return cruises.filter((cruise) => cruise.itinerary.filter((leg, index) => index > 0 && destinations.filter((dest) => locations[dest].title === leg.title).length > 0).length > 0)
    } else {
        return cruises
    }
}

export const filterCruiseLines = (cruises, cruiseLines) => {
    if (cruiseLines.length > 0) {
        return cruises.filter(cruise => cruiseLines.indexOf(cruise.cruiseLine) !== -1)
    } else {
        return cruises
    }
}
