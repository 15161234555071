import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { Clear, FilterAlt } from '@mui/icons-material';
import Sidebar from './Filters/Sidebar';
import NavBar from './NavBar';
import { useEffect, useState } from 'react';
import { allCruises } from './util/data/cruises';
import { locations } from './util/data/locations';
import { filterCruiseLines, filterDeparture, filterDestinations } from './util/util';
import React from 'react';
import CruiseMap from './Map/CruiseMap';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
  // @ts-ignore
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  // @ts-ignore
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Main() {
  const [open, setOpen] = useState(true);
  const [cruiseLines, setCruiseLines] = useState([])
  const [destinations, setDestinations] = useState([])
  const [departure, setDeparture] = useState(1)

  const [cruises, setCruises] = useState([])

  useEffect(() => {
    setCruises(allCruises)
  }, [])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <FilterAlt />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{
            mr: 5,
            display: { xs: 'none', md: 'flex', },
            fontFamily: 'arial',
            fontWeight: 300,
            letterSpacing: '.2rem',
            color: 'inherit',
            textDecoration: 'none',
          }}>
            {/* <DirectionsBoat sx={{ mt: 1, mr: 1.5 }} /> */}
            {/* FIND<DirectionsBoat sx={{ mt: .5, mr: .5 }} />MY<DirectionsBoat sx={{ mt: .5, mr: .5 }} />CRUISE */}
            Find&bull;My&bull;Cruise
          </Typography>
          <NavBar locations={locations} cruiseLines={cruiseLines} destinations={destinations}
            departure={departure} setDeparture={setDeparture} cruises={cruises} />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <Clear />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <Sidebar open={open} locations={locations}
            destinations={destinations} setDestinations={setDestinations}
            cruiseLines={cruiseLines} setCruiseLines={setCruiseLines} cruises={cruises} departure={departure} />
        </List>
        <Divider />

      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        <CruiseMap locations={locations} destinations={destinations}
          cruises={filterCruiseLines(filterDestinations(filterDeparture(cruises, departure), destinations), cruiseLines)}
          departure={departure} />
      </Box>
    </Box>
  );
}
