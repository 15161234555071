import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Autocomplete, Box, TextField } from '@mui/material'
import { useEffect, useState } from 'react';
import React from 'react';
import { filterCruiseLines, filterDestinations } from './util/util';
const getCounts = (cruises, locations) => {
  return locations.map((location, index) => ({
    index,
    count: cruises.filter(cruise => cruise.itinerary[0].title === location.title).length,
    ...location
  }))
}
const NavBar = ({ locations, departure, setDeparture, cruises, cruiseLines, destinations }) => {
  const [counts, setCounts] = useState([])
  useEffect(() => {
    setCounts(getCounts(filterCruiseLines(filterDestinations(cruises, destinations), cruiseLines), locations))
  }, [cruises, destinations, cruiseLines, locations])

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const handleChange = (event, value) => {
    if (value)
      setDeparture(value.index);
    else
      setDeparture(value)
  };


  return <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
    <ThemeProvider theme={darkTheme}>
      <Autocomplete
        // multiple
        size="small"
        id="departure-search"
        options={counts.filter(location => location.count > 0)}
        getOptionLabel={(location) => `${location.title}` + (location.count !== undefined ? ` (${location.count})` : '')}
        value={locations[departure]}
        onChange={handleChange}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label="Depart From"
            placeholder="Depart From"

          />
        )}
        sx={{ my: 1, flexGrow: 2 }}
      />
    </ThemeProvider>
  </Box>
}

export default NavBar