export const locations = [
    { title: 'Sydney, Australia', position: [-33.865143, 151.209900] },
    { title: 'Los Angeles, California', position: [33.770050, -118.193741] },
    { title: 'Brisbane, Australia', position: [-27.3819212, 153.15336] },
    { title: 'Nadi, Fiji', position: [-17.753871, 177.403535] },
    { title: 'Cabo San Lucas, Mexico', position: [22.697095, -110.121083] },

    { title: 'Post Moresby, Papua New Guinnea', position: [-9.494070, 147.160387] },
    { title: 'San Diego, California', position: [32.718766, -117.176382] },
    { title: 'Vancouver, Canada', position: [49.287343, -123.246008] },
    { title: 'Seattle, Washington', position: [47.768047, -122.436236] },
    { title: 'Ketchikan, Alaska', position: [55.318511, -131.634021] },
    { title: 'Junneau, Alaska', position: [58.168763, -134.379874] },
    { title: 'Glacier Bay, Alaska', position: [58.903701, -136.874656] },
    { title: 'Skagway, Alaska', position: [59.381061, -135.370911] },
    { title: 'Rosarito, Mexico', position: [32.364725, -117.072160] },
    { title: 'Ensanada, Mexico', position: [31.850002, -116.625367] },
]