import { AttachMoney, KingBed, LocationOn, Masks, People, StarHalf, Timer } from "@mui/icons-material"
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import React from "react"
import { cruiseLinesList } from "../util/data/cruiseLines"
import CruiseLines from "./CruiseLines"
import Locations from "./Locations"

const Sidebar = ({ open, locations, destinations, setDestinations, cruiseLines, setCruiseLines, cruises, departure }) => {

  return <>
    <Locations open={open} locations={locations} destinations={destinations} setDestinations={setDestinations} cruises={cruises} departure={departure} cruiseLines={cruiseLines} />
    <CruiseLines open={open}
      cruiseLinesList={cruiseLinesList}
      cruiseLines={cruiseLines}
      setCruiseLines={setCruiseLines} cruises={cruises} destinations={destinations} departure={departure} />
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          <AttachMoney />
        </ListItemIcon>
        <ListItemText primary='Price' sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          <Timer />
        </ListItemIcon>
        <ListItemText primary='Trip Length' sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          <People />
        </ListItemIcon>
        <ListItemText primary='Ship Size' sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          <StarHalf />
        </ListItemIcon>
        <ListItemText primary='Star Rating' sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          <KingBed />
        </ListItemIcon>
        <ListItemText primary='Cabin Types' sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          <Masks />
        </ListItemIcon>
        <ListItemText primary='Mask Required' sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  </>
}

export default Sidebar