import { DirectionsBoat, ExpandLess, ExpandMore } from "@mui/icons-material"
import { Autocomplete, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from "@mui/material"
import React from "react"
import { useEffect, useState } from "react"
import { filterDeparture, filterDestinations } from "../util/util"
const getCounts = (cruises, cruiseLinesList) => {
    return cruiseLinesList.map((cruiseLine) => ({
        count: cruises.filter(cruise => cruise.cruiseLine === cruiseLine).length,
        cruiseLine: cruiseLine
    }))
}
const CruiseLines = ({ open, cruiseLines, setCruiseLines, cruiseLinesList, cruises, destinations, departure }) => {
    const [counts, setCounts] = useState([])
    useEffect(() => {
        setCounts(getCounts(filterDeparture(filterDestinations(cruises, destinations), departure), cruiseLinesList))
    }, [cruises, destinations, cruiseLines, departure, cruiseLinesList])

    const [openCruiseLines, setOpenCruiseLines] = useState(true);

    const handleClick = () => {
        setOpenCruiseLines(!openCruiseLines);
    };

    const handleChange = (event, value) => {
        setCruiseLines(value.map(line => line.cruiseLine));
    };

    return <>
        <ListItem disablePadding sx={{ display: 'block' }} onClick={handleClick}>
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                    }}
                >
                    <DirectionsBoat />
                </ListItemIcon>
                <ListItemText primary='Cruise Lines' sx={{ opacity: open ? 1 : 0 }} />
                {open ? (openCruiseLines ? <ExpandLess /> : <ExpandMore />) : null}
            </ListItemButton>
        </ListItem>
        <Collapse in={openCruiseLines && open} timeout="auto" unmountOnExit>
            <List component="div" >
                <Autocomplete
                    multiple
                    size="small"
                    id="cruiseLine-search"
                    options={counts}
                    // @ts-ignore
                    getOptionLabel={(option) => `${option.cruiseLine} ` + (option.count !== undefined ? `(${option.count})` : '')}

                    onChange={handleChange}
                    value={cruiseLines.map(cruiseLine => ({ cruiseLine: cruiseLine }))}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            size="small"

                        />
                    )}
                    sx={{ mx: 1 }}
                />
            </List>
        </Collapse>
    </>
}

export default CruiseLines