export const allCruises = [
    {
        cruiseLine: 'P&O',
        itinerary: [
            { title: 'Sydney, Australia', position: [-33.865143, 151.209900] },
            { title: 'Sea Day', position: [-30.386018, 154.5758271] },
            { title: 'Brisbane, Australia', position: [-27.3819212, 153.15336] },
            { title: 'Sydney, Australia', position: [-33.865143, 151.209900] },

        ]
    },
    {
        cruiseLine: 'Carnival',
        itinerary: [
            { title: 'Brisbane, Australia', position: [-27.3819212, 153.15336] },
            { title: 'Sea Day', position: [-24.962207, 169.052594] },
            { title: 'Nadi, Fiji', position: [-17.753871, 177.403535] },
        ]
    },
    {
        cruiseLine: 'Holland America',
        itinerary: [
            { title: 'Sydney, Australia', position: [-33.865143, 151.209900] },
            { title: 'Sea Day', position: [-28.946729, 155.548000] },
            { title: 'Brisbane, Australia', position: [-27.3819212, 153.15336] },
            { title: 'Sea Day', position: [-16.176081, 150.891149] },
            { title: 'Post Moresby, Papua New Guinnea', position: [-9.494070, 147.160387] },
        ]
    },
    {
        cruiseLine: 'Holland America',
        itinerary: [
            { title: 'Seattle, Washington', position: [47.768047, -122.436236] },
            { title: 'Vancouver, Canada', position: [49.287343, -123.246008] },
            { title: 'Sea Day', position: [51.381762, -128.310831] },
            { title: 'Ketchikan, Alaska', position: [55.318511, -131.634021] },
            { title: 'Junneau, Alaska', position: [58.168763, -134.379874] },
            { title: 'Glacier Bay, Alaska', position: [58.903701, -136.874656] },
            { title: 'Skagway, Alaska', position: [59.381061, -135.370911] },
            { title: 'Sea Day', position: [51.381762, -128.310831] },
            { title: 'Vancouver, Canada', position: [49.287343, -123.246008] },
            { title: 'Seattle, Washington', position: [47.768047, -122.436236] },
        ]
    },
    {
        cruiseLine: 'Princess Cruises',
        itinerary: [
            { title: 'Vancouver, Canada', position: [49.287343, -123.246008] },
            { title: 'Sea Day', position: [51.381762, -128.310831] },
            { title: 'Glacier Bay, Alaska', position: [58.903701, -136.874656] },
            { title: 'Skagway, Alaska', position: [59.381061, -135.370911] },
            { title: 'Ketchikan, Alaska', position: [55.318511, -131.634021] },
            { title: 'Junneau, Alaska', position: [58.168763, -134.379874] },

            { title: 'Sea Day', position: [51.381762, -128.310831] },
            { title: 'Vancouver, Canada', position: [49.287343, -123.246008] },
        ]
    },
    {
        cruiseLine: 'Carnival',
        itinerary: [
            { title: 'Los Angeles, California', position: [33.770050, -118.193741] },
            { title: 'San Diego, California', position: [32.718766, -117.176382] },
            { title: 'Sea Day', position: [32.509163, -117.215315] },
            { title: 'Rosarito, Mexico', position: [32.364725, -117.072160] },
            { title: 'Ensanada, Mexico', position: [31.850002, -116.625367] },
            { title: 'Guadalupe Island, Mexico', position: [29.149751, -118.183770] },
            { title: 'San Diego, California', position: [32.718766, -117.176382] },
            { title: 'Los Angeles, California', position: [33.770050, -118.193741] },
        ],
    },
    {
        cruiseLine: 'Carnival',
        itinerary: [
            { title: 'Los Angeles, California', position: [33.770050, -118.193741] },
            { title: 'Catalina Island, California', position: [33.462688, -118.479632] },
            { title: 'Sea Day', position: [32.509163, -117.215315] },
            { title: 'Rosarito, Mexico', position: [32.364725, -117.072160] },
            { title: 'Ensanada, Mexico', position: [31.850002, -116.625367] },

        ],
    },
    {
        cruiseLine: 'Holland America',
        itinerary: [
            { title: 'Los Angeles, California', position: [33.770050, -118.193741] },
            { title: 'San Diego, California', position: [32.718766, -117.176382] },
            { title: 'Sea Day', position: [32.509163, -117.215315] },
            { title: 'Ensanada, Mexico', position: [31.850002, -116.625367] },
            { title: 'Sea Day', position: [28.186785, -116.429039] },
            { title: 'Cabo San Lucas, Mexico', position: [22.697095, -110.121083] },
        ],
    },
]